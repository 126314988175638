<template>
    <div>
        <!-- head title -->
        <div
                class="d-inline-flex cursor-pointer align-center"
                @click="$emit('prevStep')"
        >
            <v-icon color="darkprimary">mdi-arrow-left</v-icon>
            <span class="darkprimary--text font-16 mx-2">{{$t('dashboard.back')}}</span>
        </div>
        <div class="mt-4">
      <span class="greytext--text font-21 d-block">{{
          $t("forms.welcomeDoctor")
          }}</span>
            <span class="darkprimary--text font-33 font-700 d-block mt-5"
            >{{ $t("forms.formAlso") }}
      </span>
        </div>
        <v-form
                v-model="valid"
                ref="doctorGeneralForm"
                @submit.prevent="handleNext"
        >
            <!-- phone number -->
            <div class="mt-5">
                <v-row align="end">
                    <v-col cols="12" sm="6">
                        <base-auto-complete
                                :isPhone="true"
                                placeholder="Lebanon(+961)"
                                :items="filteredCountries"
                                itemValue="id"
                                itemText="name"
                                v-model="obj.country_code"
                                isCountrySelect
                        ></base-auto-complete>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <base-input
                                v-model="obj.phone"
                                placeholder="99999999999"
                        ></base-input>
                    </v-col>
                </v-row>
            </div>

            <!-- Birth day -->
            <div class="mt-4">
        <span class="darktext--text font-18 font-600">{{
            $t("forms.birthDate")
            }}</span>
                <date-dropdown default="1950-01-01" v-model="obj.birthday"/>
            </div>

            <!-- gender -->
            <div class="mt-6">
        <span class="darktext--text font-18 font-600">{{
            $t("forms.gender")
            }}</span>
                <v-radio-group v-model="genderValue">
                    <v-row>
                        <v-col
                                class="pt-0"
                                cols="12"
                                sm="6"
                                v-for="(gender, i) in genders"
                                :key="i"
                        >
                            <div class="radio-cover px-2 py-4">
                                <v-radio :label="gender.title" :value="gender.value"></v-radio>
                            </div>
                        </v-col>
                    </v-row>
                </v-radio-group>
            </div>

            <!-- btn -->
            <base-register-btn
                    class="mt-7"
                    :title="$t('forms.next')"
                    :loading="loading"
                    type="submit"
            >
            </base-register-btn>
        </v-form>
    </div>
</template>

<script>
import DateDropdown from "vue-date-dropdown";
import {mapGetters} from "vuex";
import BaseAutoComplete from "../../../dashboard/components/BaseAutoComplete.vue";

export default {
    props: {
        emailObj: {
            type: Object,
            default: () => {
            },
        },
    },
    components: {
        DateDropdown,
        BaseAutoComplete,
    },
    data: (vm) => ({
        genders: [
            {title: vm.$t("forms.male"), value: "male"},
            {title: vm.$t("forms.female"), value: "female"},
        ],
        genderValue: "male",
        obj: {},
        loading: false,
    }),
    computed: {
        ...mapGetters(["countries"]),
        filteredCountries() {
            return this.countries.countries.map(item => {
                return {
                    ...item,
                    name: item.name + ' ' + ` (+${item.phone_code}) `
                }
            })
        }
    },
    methods: {
        async handleNext() {
            let valid = this.$refs.doctorGeneralForm.validate();
            if (!valid) {
                return;
            }
            this.loading = true;

            let formData = new FormData();
            formData.append("first_name", this.emailObj.first_name);
            formData.append("last_name", this.emailObj.last_name);
            formData.append("phone", this.obj.phone);
            formData.append("gender", this.genderValue);
            formData.append("birthday", this.obj.birthday.replaceAll(".", "/"));
            formData.append("email", this.emailObj.doctor.email);
            formData.append("country_code", this.obj.country_code);

            let res = await this.$axios.post(
                "doctor/complete/registration",
                formData,
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );

            this.loading = false;
            if (res.data) {
                this.$router.push(`/complete`);
                //this.$router.push(`/sign-contract?token=${res.data.data.token}`);
            } else {
                this.$store.dispatch("showSnack", {
                    text: res.errors[Object.keys(res.errors)[0]][0],
                    color: "error",
                });
            }
        },
    },
    created() {
    }
};
</script>

<style lang="scss">
.radio-cover {
  background: $lightgreen;
  border: 1px solid $bordercolor;
  border-radius: 10px;

  .v-radio {
    label {
      color: #757575 !important;
    }
  }
}

.date-dropdown-select {
  background: #f4f9ff !important;
  border: 1px solid #e0eeff !important;
  border-radius: 5px;
}

.date-dropdown-select {
  width: 150px !important;
}

select:focus-visible {
  border-color: transparent !important;
  outline: 0px !important;
}
</style>
